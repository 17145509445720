import React from "react";
import "./ForexBooks1LP.css";
import HeroSection from "./sections/HeroSection";
import BenefitsSection from "./sections/BenefitsSection";
import Header from "../HumanSuperComputerBookPage/section/Header";
import TestimonialsSection from "./sections/TestimonialSection";
import Footer from "../HumanSuperComputerBookPage/section/Footer";
import styled from "styled-components";
import { motion } from "framer-motion";

const ForexBooks1LP = () => {
  const originalPrice = "149.99";
  const price = "17.99";
  const currency = "$";
  const discountPercent = "88%";

  const today = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handleBtnClick = () => {
    window.open(
      "https://librarylife.myshopify.com/products/forex-trading-mastery-complete-4-book-series",
      "_blank"
    );
  };
  
  const buyButton = (
    <>
      <CTAButtons>
        <CTAButton onClick={handleBtnClick}
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.4, delay: 0.6 }}
          whileHover={{ scale: 1.1 }}
        >
          Get All 4 Books Now for {currency}
          {price}
        </CTAButton>
      </CTAButtons>
      <DiscountPercent1>Offer Ends Midnight of {today} </DiscountPercent1>
      <DiscountPercent>Save {discountPercent} </DiscountPercent>

    </>
  );




  return (
    <div>
      <Header />
      <HeroSection
        currency={currency}
        price={price}
        originalPrice={originalPrice}
        discountPercent={discountPercent}
        buyButton={buyButton}
      />
      <BenefitsSection
        currency={currency}
        price={price}
        originalPrice={originalPrice}
        discountPercent={discountPercent}
        buyButton={buyButton}
      />
      <TestimonialsSection
        currency={currency}
        price={price}
        originalPrice={originalPrice}
        discountPercent={discountPercent}
        buyButton={buyButton}
      />
      <Footer />
    </div>
  );
};

export default ForexBooks1LP;

const CTAButtons = styled.div`
  text-align: center;
  gap: 20px;
  margin-bottom: 10px;
`;

const CTAButton = styled(motion.button)`
  background-color: #3498db;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }

  animation: bounce 5s infinite;

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 12px 24px;
  }
`;

const PriceTag = styled(motion.div)`
  font-size: 1.5rem;
  margin-top: 20px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    flex-direction: column;
    align-items: center;
  }
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #e74c3c;
`;

const DiscountedPrice = styled.span`
  color: #2ecc71;
  font-weight: bold;
`;

const DiscountPercent = styled.span`
  margin-bottom: 20px;
  color: #e74c3c;
  font-weight: bold;
  font-size: 1.2rem;
`;

const DiscountPercent1 = styled.span`
  color: #e74c3c;
  font-weight: bold;
  font-size: 1rem;
`;
