import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/HumanSuperComputerBookPage/LandingPage";
import ForexBooks1LP from "./pages/ForexBooks1/ForexBooks1LP";

function App() {
  return (
    <div className="App">
      {/* <LandingPage /> */}
      <Router>
        <Routes>
          <Route path={"/"} element={<LandingPage />} />
          <Route path={"/forex-books-1"} element={<ForexBooks1LP />} />

          {/* <Route exact path={"/*"} element={<HomePage />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
