import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>Email: info@librarylife.com</p>
          {/* <p>Phone: (123) 456-7890</p> */}
        </div>
        {/* <div className="footer-section">
          <h3>Follow Us</h3>
          <ul className="footer-socials">
            <li><a href="#" aria-label="Facebook">Facebook</a></li>
            <li><a href="#" aria-label="Twitter">Twitter</a></li>
            <li><a href="#" aria-label="Instagram">Instagram</a></li>
            <li><a href="#" aria-label="LinkedIn">LinkedIn</a></li>
          </ul>
        </div> */}
        <div className="footer-section">
          <h3>Subscribe</h3>
          <form className="footer-form">
            <input type="email" placeholder="Your email address" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 LibraryLife. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
