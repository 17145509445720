import React, { useState, useEffect } from "react";
import './CountdownTimer.css'

const CountdownTimer = ({ deadline }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(deadline) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="countdown-timer">
      {timeLeft.minutes > 0 || timeLeft.seconds > 0 ? (
        <span>
          {timeLeft.minutes} minutes {timeLeft.seconds} seconds
        </span>
      ) : (
        <span>Offer Expired</span>
      )}
    </div>
  );
};

export default CountdownTimer;
