import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import bookcover1 from "../../../images/Forex Trading/1.jpg";
import bookcover2 from "../../../images/Forex Trading/2.jpg";
import bookcover3 from "../../../images/Forex Trading/3.jpg";
import bookcover4 from "../../../images/Forex Trading/4.jpg";

const HeroSection = ({
  originalPrice,
  price,
  currency,
  discountPercent,
  buyButton,
}) => {
  const [arrow, setArrow] = useState("→");



  useEffect(() => {
    // Create a MediaQueryList object
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    // Add the event listener
    const handleChange = (event) => handleScreenSizeChange(event.matches);
    mediaQuery.addEventListener("change", handleChange);

    // Check the initial screen size
    handleScreenSizeChange(mediaQuery.matches);

    // Cleanup the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  const handleScreenSizeChange = (matches) => {
    if (matches) {
      console.log("Screen width is less than 768px");
      setArrow("↓");

      // Perform your action here
    } else {
      console.log("Screen width is greater than or equal to 768px");
      setArrow("→");
      // Perform your action here
    }
  };

  return (
    <Section>
      <Container>
        <Content>
          <TopLine
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            Master Forex Trading at Unbelievable Value!
          </TopLine>
          <Heading
            initial={{ opacity: 0, y: -0 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Become a <Highlight>Professional Trader</Highlight> in 1 Month
          </Heading>
          <SubHeading1
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            Each book in our series is designed to take you one step closer to
            becoming a <Highlight>professional trader</Highlight>. Master{" "}
            <Highlight>one book per week</Highlight> and watch your trading
            skills grow exponentially.
            {/* Learn from the best with our  4-book series</Highlight> */}
            {/* That's an <Highlight>88% discount</Highlight> for a limited time only. */}
          </SubHeading1>
          <BookProgression>
            <BookCard>
              <BookImage src={bookcover1} alt="Book 1" />
              <BookTitle>Forex Trading for Beginners</BookTitle>
              <AnimatedArrow>{arrow}</AnimatedArrow>
            </BookCard>
            <BookCard>
              <BookImage src={bookcover2} alt="Book 2" />
              <BookTitle>Introduction to Trading System Development</BookTitle>
              <AnimatedArrow>{arrow}</AnimatedArrow>
            </BookCard>
            <BookCard>
              <BookImage src={bookcover3} alt="Book 3" />
              <BookTitle>Constructing the Best Trading Strategy</BookTitle>
              <AnimatedArrow>{arrow}</AnimatedArrow>
            </BookCard>
            <BookCard>
              <BookImage src={bookcover4} alt="Book 4" />
              <BookTitle>Advanced and Profitable Trading Strategies</BookTitle>
              {arrow == "→" && (
                <>
                  <div style={{ paddingTop: "5px" }}></div>
                  <FinalStep>Professional Trader</FinalStep>
                  <div style={{ paddingTop: "15px" }}></div>
                </>
              )}
            </BookCard>
          </BookProgression>
          <SubHeading
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            originally priced at{" "}
            <Strikethrough>
              {currency}
              {originalPrice}
            </Strikethrough>
            , now just{" "}
            <Highlight>
              {currency}
              {price}
            </Highlight>
            !
            {/* That's an <Highlight>88% discount</Highlight> for a limited time only. */}
          </SubHeading>
          {buyButton}

          {/* <PriceTag
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.8 }}
                    >
                        <OriginalPrice>{currency}{originalPrice}</OriginalPrice> <DiscountedPrice>{currency}{price}</DiscountedPrice>
                        <DiscountPercent>Save 88%</DiscountPercent>
                    </PriceTag> */}
        </Content>
      </Container>
    </Section>
  );
};

export default HeroSection;

const Section = styled.section`
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: 0px 20px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 10px;
`;

const TopLine = styled(motion.h4)`
margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #3498db;
  margin-bottom: 10px;
`;

const Heading = styled(motion.h1)`
  font-size: 3rem;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.2;
  color: #222;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const SubHeading1 = styled(motion.h2)`
  margin: 0px 40px;
  width: 100%;  /* Ensure the paragraph fills the container width */
  font-size: 1.3rem;
  margin-bottom: 20px;
  max-width: 800px;
  color: #555;

  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: justify;

  }
`;

const SubHeading = styled(motion.h2)`
  margin: 0px 40px;
  width: 100%;  /* Ensure the paragraph fills the container width */
  font-size: 1.3rem;
  margin-bottom: 20px;
  max-width: 800px;
  color: #555;

  @media (max-width: 768px) {
    font-size: 1rem;

  }
`;

const Highlight = styled.span`
  color: #3498db;
  font-weight: bold;
`;

const Strikethrough = styled.span`
  text-decoration: line-through;
  color: #e74c3c;
`;

const BookProgression = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;

  }
`;

const BookCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const BookImage = styled.img`
  width: 150px;
  height: 200px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 250px;
    height: 300px;
  }
`;

const BookTitle = styled.div`
  font-size: 1rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const AnimatedArrow = styled.div`
  font-size: 2rem;
  color: #3498db;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  @media (max-width: 768px) {
    transform: rotate(90deg); /* Rotate the arrow to point down */
    font-size: 1.5rem;
  }
`;

const FinalStep = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #2ecc71;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CTAButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
`;

const CTAButton = styled(motion.button)`
  background-color: #3498db;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 12px 24px;
  }
`;

const PriceTag = styled(motion.div)`
  font-size: 1.5rem;
  margin-top: 20px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    flex-direction: column;
    align-items: center;
  }
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #e74c3c;
`;

const DiscountedPrice = styled.span`
  color: #2ecc71;
  font-weight: bold;
`;

const DiscountPercent = styled.span`
  margin-bottom: 20px;
  color: #e74c3c;
  font-weight: bold;
  font-size: 1.2rem;
`;
