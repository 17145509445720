import React from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion";

const TestimonialsSection = ({originalPrice , price, currency, discountPercent , buyButton}) => {
    return (
        <Section>
            <Container>
                <Heading>
                    What Our Readers Are Saying
                </Heading>
                <Testimonials>
                    <TestimonialCard>
                        <Quote>“These books transformed my trading journey! I went from a complete beginner to confidently making trades within a month.”</Quote>
                        <Author>- Alex M.</Author>
                    </TestimonialCard>
                    <TestimonialCard>
                        <Quote>“The strategies outlined in these books are easy to follow and incredibly effective. I've seen a noticeable improvement in my trading results.”</Quote>
                        <Author>- Sarah</Author>
                    </TestimonialCard>
                    <TestimonialCard>
                        <Quote>“If you're serious about trading, this is the series for you. The insights are invaluable, and the progression through the books is perfectly paced.”</Quote>
                        <Author>- David</Author>
                    </TestimonialCard>
                </Testimonials>
                {buyButton}
            </Container>
        </Section>
    );
};

export default TestimonialsSection;

const Section = styled.section`
    background-color: #fff;
    color: #333;
    padding: 60px 20px;
    text-align: center;
`;

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
`;

const Heading = styled.h2`
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #222;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const Testimonials = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 50px;

    @media (min-width: 769px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const TestimonialCard = styled.div`
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    text-align: center;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const Quote = styled.p`
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const Author = styled.p`
    font-size: 1rem;
    font-weight: bold;
    color: #3498db;
`

;

