import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import Header from "./section/Header";
import Footer from "./section/Footer";
import bookCover from "../../images/supercomputer2.png";
import img1 from "../../images/1.jpg";
import img3 from "../../images/3.jpg";
import img2 from "../../images/2.jpg";
import CountdownTimer from "./section/CountdownTimer";

const LandingPage = () => {
  const today = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const deadline = new Date(new Date().getTime() + 10 * 60000);


  const originalPrice = "49.99";
  const price = "7.99";
  const currency = "$";

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  

  const handleBtnClick = () => {
    window.open(
      "https://librarylife.myshopify.com/products/become-a-human-supercomputer-instant-download",
      "_blank"
    );
  };

  const buyButton = (
    <button onClick={handleBtnClick} className="buy-now">
      Get Instant Access Now {currency}
      {price}
    </button>
  );

  return (
    <>
      <Header />
      <div className="hero-section">
        <div className="container">
          <h1 className="main-heading">Unlock the Power of Your Mind!</h1>
          <p className="sub-heading">Become a Human Supercomputer</p>
          <p className="description">
            Transform your life with{" "}
            <strong>unmatched memory techniques</strong>,{" "}
            <strong>speed learning methods</strong>, and{" "}
            <strong>advanced cognitive strategies</strong>. This comprehensive
            guide is your key to mastering information processing and boosting
            your productivity.
          </p>

          <div className="content-wrapper">
            <div className="left-content">
              <img src={bookCover} alt="Book Cover" className="book-cover" />
            </div>
            <div className="right-content">
              <div className="tick-list">
                <ul>
                  <li>
                    Enhance your cognitive abilities and increase productivity
                  </li>
                  <li>No prior technical knowledge required</li>
                  <li>Save hours of study and work time daily</li>
                  <li>Boost your earning potential significantly</li>
                  <li>Develop a photographic memory</li>
                  <li>Achieve peak mental performance</li>
                  <li>Improve problem-solving and analytical skills</li>
                </ul>
              </div>
            </div>
          </div>

          <p className="price-tag">
            Originally{" "}
            <span className="original-price">
              {currency}
              {originalPrice}
            </span>{" "}
            now{" "}
            <span className="discounted-price">
              {currency}
              {price}
            </span>
            !
          </p>
          <p className="offer-ends">Offer ends in:</p>
          <CountdownTimer deadline={deadline} />
          {buyButton}

          <p className="description">
            Get instant download of this ebook now and receive a bonus book:{" "}
            <strong>50+ Secret AI Tools to Increase Productivity</strong>.
            <br></br>{" "}
            <span style={{ fontSize: "15px" }}>
              Don't miss out on these powerful resources!
            </span>
          </p>
          <div className="features">
            <div className="feature">
              <img src={img1} alt="Book Cover" />
              <h3>Master Information Processing</h3>
              <p>
                Learn how to quickly understand and retain large amounts of
                information.
              </p>
            </div>
            <div className="feature">
              <img src={img2} alt="Feature 2" />
              <h3>Enhance Your Memory</h3>
              <p>
                Use techniques like mnemonics, memory palaces, and visualization
                to remember everything you need.
              </p>
            </div>
            <div className="feature">
              <img src={img3} alt="Feature 3" />
              <h3>Leverage AI Tools</h3>
              <p>
                Integrate AI to streamline your learning process and gain
                valuable insights.
              </p>
            </div>
          </div>
          <div className="testimonials">
            <div className="benefits">
              <h2>Why Choose This Book?</h2>
              <ul>
                <li>Develop strategies to learn new skills rapidly.</li>
                <li>Apply practical exercises to strengthen your memory.</li>
                <li>Understand real-life examples and case studies.</li>
                <li>
                  Enhance your critical thinking and problem-solving skills.
                </li>
                <li>Leverage cutting-edge technology and AI for learning.</li>
                <li>
                  Boost your productivity with advanced time management
                  techniques.
                </li>
                <li>Stay ahead of industry trends and innovations.</li>
                <li>
                  Embrace a lifelong learning mindset for continuous growth.
                </li>
              </ul>
            </div>
          </div>
          {buyButton}
          <div className="testimonials">
            <h2>What Our Readers Say</h2>
            <div className="testimonial">
              <p>
                "This book transformed my ability to learn and retain
                information. A must-read for anyone looking to enhance their
                cognitive skills!" - Jane Doe
              </p>
            </div>
            <div className="testimonial">
              <p>
                "The techniques in this book are groundbreaking. I've never felt
                more confident in my ability to process and remember new
                information." - John Smith
              </p>
            </div>
            <div className="testimonial">
              <p>
                "A game-changer for anyone looking to boost their learning speed
                and memory retention." - Mary Johnson
              </p>
            </div>
            <div className="testimonial">
              <p>
                "Highly recommended for students and professionals alike." -
                David Lee
              </p>
            </div>
          </div>
          {buyButton}
          {scrollY > 200 && (
            <button onClick={handleBtnClick} className="sticky-buy-now">
              Limited Time Offer <span className="original-price">
              {currency}
              {originalPrice}
            </span> {currency}
              {price}

            </button>
          )}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
