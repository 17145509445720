import React, { useState } from 'react';
import './Header.css';
import logo from '../../../images/logo4.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        <img src={logo} alt="Logo" className="header-logo" />
        <nav className={`header-nav ${menuOpen ? 'open' : ''}`}>
          <ul className="header-menu">
            <li className="header-menu-item"><a href="#features">Features</a></li>
            <li className="header-menu-item"><a href="#benefits">Benefits</a></li>
            <li className="header-menu-item"><a href="#testimonials">Testimonials</a></li>
          </ul>
        </nav>
        <div className="header-hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
}

export default Header;
