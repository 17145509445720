import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import bookcover1 from '../../../images/Forex Trading/1.jpg';
import bookcover2 from '../../../images/Forex Trading/2.jpg';
import bookcover3 from '../../../images/Forex Trading/3.jpg';
import bookcover4 from '../../../images/Forex Trading/4.jpg';
import { motion } from "framer-motion";

const BenefitsSection = ({originalPrice , price, currency, discountPercent , buyButton}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };
    

    return (
        <Section>
            <Container>
                <Heading>
                    Unlock the Secrets to Professional Trading
                </Heading>
                <SubHeading>
                    Each book in our series is designed to take you one step closer to becoming a professional trader. Master one book per week and watch your trading skills grow exponentially.
                </SubHeading>
                <SliderContainer>
                    <StyledSlider {...settings}>
                        <Slide>
                            <ImageAndContent>
                                <BookImage src={bookcover1} alt="Book 1" />
                                <BookContent>
                                    <BookTitle>Forex Trading for Beginners</BookTitle>
                                    <BookDescription>
                                        Start your trading journey with a solid foundation. Learn the basics of Forex trading, including key concepts, market analysis, and essential strategies to get you started.
                                    </BookDescription>
                                </BookContent>
                            </ImageAndContent>
                        </Slide>
                        <Slide>
                            <ImageAndContent>
                                <BookImage src={bookcover2} alt="Book 2" />
                                <BookContent>
                                    <BookTitle>Introduction to Trading System Development</BookTitle>
                                    <BookDescription>
                                        Develop your own trading systems with ease. This book guides you through creating, testing, and optimizing trading systems that align with your trading style and goals.
                                    </BookDescription>
                                </BookContent>
                            </ImageAndContent>
                        </Slide>
                        <Slide>
                            <ImageAndContent>
                                <BookImage src={bookcover3} alt="Book 3" />
                                <BookContent>
                                    <BookTitle>Constructing the Best Trading Strategy</BookTitle>
                                    <BookDescription>
                                        Learn how to construct and implement winning trading strategies. This book provides a comprehensive framework for building strategies that work in real-world trading.
                                    </BookDescription>
                                </BookContent>
                            </ImageAndContent>
                        </Slide>
                        <Slide>
                            <ImageAndContent>
                                <BookImage src={bookcover4} alt="Book 4" />
                                <BookContent>
                                    <BookTitle>Advanced and Profitable Trading Strategies</BookTitle>
                                    <BookDescription>
                                        Take your trading to the next level with advanced strategies that are proven to be profitable. Learn how to maximize your gains and minimize your risks in the Forex market.
                                    </BookDescription>
                                </BookContent>
                            </ImageAndContent>
                        </Slide>
                    </StyledSlider>
                </SliderContainer>
              
            </Container>

        </Section>
    );
};

export default BenefitsSection;

const Section = styled.section`
    background-color: #f9f9f9;
    color: #333;
    padding: 60px 20px;
    text-align: center;
`;

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
`;

const Heading = styled.h2`
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #222;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const SubHeading = styled.p`
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #555;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const SliderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`;

const StyledSlider = styled(Slider)`
    width: 100%;
    max-width: 800px;

    .slick-dots {
        bottom: -30px;
    }

    .slick-prev, .slick-next {
        color: #3498db;
    }
`;

const Slide = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px;
`;

const ImageAndContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 769px) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const BookImage = styled.img`
    width: 150px;
    height: 220px;
    margin-bottom: 20px;

    @media (min-width: 769px) {
        margin-bottom: 0;
        margin-right: 30px;
    }
`;

const BookContent = styled.div`
    max-width: 600px;
    text-align: center;

    @media (min-width: 769px) {
        text-align: left;
    }
`;

const BookTitle = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #3498db;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

const BookDescription = styled.p`
    font-size: 1rem;
    color: #666;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`
const CTAButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
`;

const CTAButton = styled(motion.button)`
  background-color: #3498db;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 12px 24px;
  }
`;

const PriceTag = styled(motion.div)`
  font-size: 1.5rem;
  margin-top: 20px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    flex-direction: column;
    align-items: center;
  }
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #e74c3c;
`;

const DiscountedPrice = styled.span`
  color: #2ecc71;
  font-weight: bold;
`;

const DiscountPercent = styled.span`
  margin-bottom: 20px;
  color: #e74c3c;
  font-weight: bold;
  font-size: 1.2rem;
`
;
